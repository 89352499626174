/* Page Home
========================================================================================= */
@keyframes example {
	0%   {bottom: 2.5rem;}
	6.25%  {bottom: 1.5rem;}
	12.5%  {bottom: 2rem;}
	18.75%  {bottom: 1.5rem;}
	25% {bottom: 2.5rem;}
	100% {bottom: 2.5rem;}
}

main#page-main {
	
	#page-top {
		height: 100vh;

		&.scroll {
			.page-top-icon {
				opacity: 0;
			}
		}

		#page-top-bg {
			height: 100%;
			background-position: 50% 50%;
			background-size: cover;
			background-repeat: no-repeat;
			position: absolute;
			width: 100%;
			z-index: -1;
		}

		#page-title {
			text-align: center;

			h2 {
				font-family: Amberlight;
				font-size: 15rem;
				line-height: 0.3;
				color: $blanc;
				font-weight: bold;
				margin: 0;
				margin-top: 4rem;
			}

			h3 {
				font-size: 2rem;
				text-transform: uppercase;
				letter-spacing: 10px;
				color: $blanc;
				font-weight: 300;
				margin: 0;
				line-height: 1.2;

				strong {
					font-size: 2.5rem;
					font-weight: 300;
				}
			}
		}

		.page-top-icon {
			position: absolute;
			color: $rose;
			transition: $transition;

			&.icon-souris {
				bottom: 4rem;
				font-size: 4rem;
			}

			&.icon-chevron-down {
				left: 50%;
				transform: translateX(-50%);
				font-size: 1rem;
				bottom: 2.5rem;
				animation-delay: 3s;
				animation-duration: 4s;
				animation-name: example;
				animation-iteration-count: infinite;
			}
		}
	}

	#home-philosophie {
		position: relative;
		margin-top: 0;
		padding: 8rem 10rem;
		min-height: 40rem;
		overflow: hidden;

		#home-philosophie-citation {
			position: relative;
			font-weight: 200;

			p {
				color: $blanc;
				font-size: 3rem;
				margin: 0;
				line-height: 2.5;

				strong {
					font-weight: bold;
					font-family: Amberlight;
					font-size: 6rem;
					color: $rose;
					line-height: 0;
					margin-left: 4px;
				}
			}

			.home-philosophie-citation-auteur {
				color: $blanc;
				font-size: 1.5rem;
				position: absolute;
				left: 50%;
				bottom: -4rem;
				transform: translateX(-50%);
				font-weight: 300;
			}
		}

		&:before, &:after {
			position: absolute;
			font-size: 30rem;
			line-height: 0;
			color: $blanc;
			z-index: 1;
		}

		&:before{
			content: "“";
			top: 157px;
			left: 28px;
		}

		&:after{
			content: "”";
			bottom: -17px;
			right: 24px;
		}
	}

	#home-presentation {
		margin-top: 6rem;

		.row {
			margin-bottom: 0;

			#home-presentation-img {
				padding-right: 10%;
				position: relative;
				z-index: 1;

				img {
					width: 100%;
				}
			}

			#home-presentation-text {
				text-align: justify;
				line-height: 2;

				h4 {
					margin-bottom: 6rem;
				}

				p {
					position: relative;

					&:first-of-type {
						margin-top: 0;

						&:before {
							content: "";
							width: 16rem;
							height: 1px;
							background-color: $gris;
							top: -3rem;
							left: -21%;
							position: absolute;
							z-index: -1;
						}
					}

					&:last-of-type {
						margin-bottom: 0;
					}
					
					strong {
						font-weight: 400;
					}
				}

				h1 {
					display: inline;
					float: right;
					line-height: 2;
					margin: 1.5rem 0;
					font-size: 4rem;
				}
			}
		}
	}

	#home-profil {
		padding-bottom: 0;

		.title-bg {
			margin-bottom: 3rem;
		}

		.row {
			margin: 0;
			position: relative;

			&:before {
				content: "";
				height: 1px;
				width: 16rem;
				position: absolute;
				top: -1px;
				left: 0;
				background-color: $gris;

			}
			&:after {
				content: "";
				height: 16rem;
				width: 1px;
				position: absolute;
				bottom: 0;
				left: 50%;
				background-color: $gris;
			}
		
			.col {
				padding: 3rem 0 6rem;

				h5 {
					padding: 0 30%;
					text-transform: uppercase;
					font-size: 3rem;
					letter-spacing: 4px;
					color: $gris;
					font-weight: 300;
					height: 100%;
					margin-bottom: 5rem;
				}
			}
		}
	}
}
