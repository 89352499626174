/* Responsive
========================================================================================= */

@media #{$extra-large-and-up} {

	header#page-header {

		nav#page-nav {

			.nav-wrapper {

				#nav-menu-top {

					ul {

						&#menu-top {

							li {
								transition: $transition;
								padding: 3rem 2rem;
							}
						}
					}
				}

				ul#mobile-demo {
					height: calc(100% - 56px);
					box-shadow: none;
					top: 5.6rem;

					li {

						&:first-child{
							padding-top: 2rem;
						}

						a {
							color: $gris;
							text-transform: uppercase;
							font-size: 1.5rem;
							letter-spacing: 2px;
							padding: 0;
							height: auto;
							line-height: 1;
							padding: 2rem 0;
						}
					}
				}
			}
		}
	}
}


@media #{$large-and-up} {

	.presentation {

		main#page-main {

			#presentation-experience {

				#presentation-experience-text {

					.row {

						.col {

							&:nth-child(2n){
								padding-right: calc(8% + 0.75rem);
								padding-left: calc(4% + 0.325rem);
							}

							&:nth-child(2n+1){
								padding-left: calc(8% + 0.75rem);
								padding-right: calc(4% + 0.325rem);
							}
						}
					}
				}
			}
		}
	}
}

@media #{$medium-and-up} {
	// styles for medium screens and larger

	.home {

		main#page-main {

			#home-presentation {

				#home-presentation-text {

					padding-left: 0;
				}
			}
		}
	}

	.presentation {

		main#page-main {

			#presentation-bio {

				#presentation-bio-img {
					padding-right: 8%;
					padding-left: 0;
				}
			}

			#presentation-formation {

				#presentation-formation-img {
					padding-left: 8%;
					padding-right: 0;
				}
			}
		}
	}
}

@media screen and (min-width: $medium-screen-up) and (max-width: $large-screen) {
	header#page-header {

		nav#page-nav {

			.nav-wrapper {

				#nav-menu-top {

					&.scroll {

						ul#menu-top {
							padding-left: 21rem;
							padding-right: 0;

							li {
								padding: 2rem 1rem;

								a {
									letter-spacing: 1px;
									font-size: 1rem;
								}
							}
						}
					}

					ul#menu-top {
						li {
							padding: 3rem 1.2rem;

							a {
								letter-spacing: 1.2px;
								font-size: 1.2rem;
							}
						}
					}

					a#nav-menu-top-logo {
						left: 1rem;
					}
				}
			}
		}
	}

}

@media #{$medium-only} {
	// styles for medium screens only

	.container-tablette {
		width: 85%;
		margin: 0 auto;
		max-width: 1280px;

		.row {
			margin-left: -.75rem;
   			margin-right: -.75rem;
		}
	}

	.error-404 {

		main#page-main {

			#error-404-content {

				h2 {
					font-size: 11rem;
					margin-bottom: -9rem;
				}

				h3 {
					font-size: 1.5rem;
					letter-spacing: 9px;

					strong {
						font-size: 2rem;
					}
				}
			}
		}
	}

	.home {

		main#page-main {

			#page-top {

				#page-title {

					h2 {
						font-size: 11rem;
					}

					h3 {
						font-size: 1.5rem;
						letter-spacing: 9px;

						strong {
							font-size: 2rem;
						}
					}
				}
			}
		}
	}

	.presentation {

		#presentation-bio {

			.row {

				#presentation-bio-text {
					padding-right: calc(8% + 0.75rem);
				}
			}
		}

		#presentation-formation {

			.row {

				#presentation-formation-text {
					padding-left: calc(8% + 0.75rem);
				}
			}
		}
	}

	.prestation {

		main#page-main {

			.prestation-item {

				&:nth-child(2n) {
					align-items: flex-end;
				}
			}
		}
	}

	.media {

		main#page-main {

			#media-photo {

				#media-photo-wrapper {

					.photo-item {
						width: 33.33%;
						height: 33.33vw;
					}
				}
			}
		}
	}
}

@media #{$large-and-down} {
	.evenement {
		.evenement-item {
			width: 100%;
		}
	}
}

@media #{$medium-and-down} {
	// styles for medium screens and down

	header#page-header {

		nav#page-nav {

			.nav-wrapper {
				text-align: center;
				transition: $transition;

				&.scroll {
					box-shadow: 0 0 20px 0 $gris;
					background-color: $blanc;

					#nav-menu-top-logo-mobile {
						opacity: 1;
						transform: translateY(0);
					}
				}

				#nav-menu-top-logo-mobile {
					transition: $transition;
					font-size: 3rem;
					position: relative;
					top: 0.25rem;
					display: inline-block;
					opacity: 0;
					transform: translateY(-10px);
				}

				a.button-collapse {
					color: $rose;
					position: absolute;
					left: 0;
					margin: 0;
					padding: 0 2rem;
					transition: $transition;

					&.open {
						transform: rotate(90deg);
					}
				}

				#mobile-demo {

					li {

						a:focus {
							background-color: $gris-blanc;
						}
					}

					li.current_page_item {

						a {
							color: $rose;
						}
					}
				}
			}
		}
	}

	.home {

		main#page-main {

			#page-top {

			}

			#home-philosophie {
				padding-right: 6rem;
				padding-left: 6rem;
			}

			#home-presentation {

				#home-presentation-text{

					h4 {
						margin-top: 3rem;
					}

					p {

						&:before {
							display: none;
						}
					}
				}
			}
		}
	}

	.page {

		main#page-main {

			#page-top {
				margin-bottom: 0rem;
			}
		}
	}

	.presentation {

		main#page-main {

			#presentation-bio {

				.row {

					#presentation-bio-text {

						p:before {
							display: none;
						}
					}
				}
			}

			#presentation-formation {

				.row {

					#presentation-formation-text {

						p:before {
							display: none;
						}
					}
				}
			}

			#presentation-experience {

				.row.flex {
					flex-direction: column;

					.col {
						margin-bottom: 6rem;

						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}

	.prestation {

		main#page-main {

			.prestation-item {
				flex-direction: column;
				min-height: inherit;

				.prestation-img-bg {
					width: calc(100% - 10%);
					max-width: none;
					height: 14rem;
				}

				.prestation-text {
					width: calc(100% - 10%);
					min-height: 14rem;

					h5 {
						font-size: 2rem;
						letter-spacing: 2px;
					}
				}
			}
		}
	}

	.media {

		main#page-main {

			#media-presse {

				.presse-item {
					padding: 0;

					&:nth-child(2n+1) {

						&:before {
							transform: translateX(-100%);
						}
					}

						&:nth-child(2n) {

						&:before {
							top: 12rem;
							transform: translateX(100%);
						}
					}
				}
			}
		}
	}

	.stretching {

		main#page-main {

			#stretching-form {
				input[type=submit] {
					width: 100%;
				}
			}
		}
	}

	.contact {

		main#page-main {

			#contact-content {

				#contact-form {
					width: 100%;
					border: 0;

					input[type=submit] {
						width: 100%;
					}
				}

				#contact-infos {
					display: none;
				}
			}
		}
	}

	.evenement {
		.evenement-item{

			&:nth-child(2n+1) {
				padding: 3rem 1.5rem 3rem 3rem;
			}

			&:nth-child(2n) {
				padding: 3rem 3rem 3rem 1.5rem;
			}

			padding: 3rem;

			.evenement-date {
			    font-size: 2rem;
				letter-spacing: 2px;

				span.evenement-date-jour {
					font-size: 3rem;
				}
			}
		}
	}

	footer#page-footer {

		#page-footer-contact {
			padding-right: 6rem;
			padding-left: 6rem;
		}

		#page-footer-top {

			.row {

				#page-footer-coordonnees {
					width: 66.6%;
				}

				#page-footer-menu {
					display: none;
				}

				#page-footer-reseaux-sociaux {
					width: 33.3%;

					a {

						i {
							width: 5rem;
							height: 5rem;
							line-height: 1.55;
							font-size: 3rem;
						}
					}
				}
			}
		}
	}
}

@media #{$small-and-down} {
	// styles for small screens and down

	.container-mobile, .container-tablette {
		width: 90%;
		margin: 0 auto;
		max-width: 1280px;

		.row {
			margin-left: -.75rem;
   			margin-right: -.75rem;
		}
	}

	.title-bg {
		font-size: 4.5rem;

		span {
			font-size: 1.8rem;
		}
	}

	.page {

		main#page-main {

			#page-top {
				height: 33vh;

				#page-title {

					h2 {
						font-size: 7rem;
						margin-top: 7rem;
					}
				}
			}
		}
	}

	.error-404 {

		main#page-main {

			#error-404-content {

				h2 {
					font-size: 18vw;
					margin-bottom: -4rem;
				}

				h3 {
					font-size: 1.2rem;
					letter-spacing: 5px;

					strong {
						font-size: 1.4rem;
					}
				}
			}
		}
	}

	.home {

		main#page-main {

			#page-top {

				#page-title {

					h2 {
						font-size: 18vw;
					}

					h3 {
						font-size: 1.2rem;
						letter-spacing: 5px;

						strong {
							font-size: 1.4rem;
						}
					}
				}

				.page-top-icon {

					&.icon-souris {
						display: none;
					}
				}
			}

			#home-philosophie {
				padding-right: 4rem;
				padding-left: 4rem;

				#home-philosophie-citation {

					p {
						font-size: 1.5rem;
						line-height: 2.5;

						strong {
							font-size: 3rem;
							margin-left: 4px;
						}
					}

					.home-philosophie-citation-auteur {
						font-size: 1rem;
					}
				}

				&:before, &:after {
					font-size: 15rem;
				}

				&:before{
					top: 98px;
					left: 34px;
				}

				&:after{
					bottom: 11px;
					right: 32px;
				}
			}

			#home-presentation {

				.row.flex {
					flex-direction: column;

					#home-presentation-img {
						padding-right: 0.75rem;
					}

					#home-presentation-text {

						h4 {
							margin-bottom: 3rem;
							margin-top: 3rem;
						}
					}
				}
			}

			#home-profil {
				padding-bottom: 12rem;

				.row.flex {
					flex-direction: column;

					.col {
						padding-bottom: 6rem;

						&:last-child {
							padding-bottom: 0;
						}

						h5 {
							margin-bottom: 3rem;
						}
					}

					&:before, &:after {
						display: none;
					}
				}
			}
		}
	}

	.presentation {

		main#page-main {

			#presentation-bio {

				.row.flex {
					flex-direction: column;

					#presentation-bio-text {
						padding-right: 0.75rem;

						h4 {
							margin-bottom: 3rem;
							margin-top: 3rem;
						}
					}
				}
			}

			#presentation-formation {

				.row.flex {
					flex-direction: column-reverse;

					#presentation-formation-text {
						padding-left: 0.75rem;

						h4 {
							margin-bottom: 3rem;
							margin-top: 3rem;
						}
					}
				}
			}

			#presentation-experience {

				.row.flex {
					flex-direction: column;
				}
			}
		}
	}

	.prestation {

		main#page-main {

			.prestation-item {
				align-items: center;

				&:nth-child(2n+1) {

					&:before {
						transform: translateX(-100%);
					}
				}

				&:nth-child(2n) {

					&:before {
						transform: translateX(100%);
					}
				}

				.prestation-img-bg {
					width: 100%;
				}

				.prestation-text {
					width: 100%;
					padding: 2.5rem 3rem;
				}
			}
		}
	}


	.media {

		main#page-main {

			#media-photo {

				#media-photo-wrapper {

					.photo-item {
						width: 50%;
						height: 50vw;
					}
				}
			}
		}
	}

	.stretching {
		#stretching-form {
			.row {
				.col.m6:first-child {
					margin-bottom: 2rem;
				}
			}
		}
	}

	.contact {

		main#page-main {

			#contact-content,  {

				#contact-form {

					.row {

						.col.m6:first-child {
							margin-bottom: 2rem;
						}
					}
				}
			}
		}
	}

	footer#page-footer {

		#page-footer-contact {
			padding-right: 4rem;
			padding-left: 4rem;
		}

		#page-footer-top {

			.row {
				flex-direction: column;

				#page-footer-coordonnees, #page-footer-reseaux-sociaux {
					width: 100%;
				}
			}
		}
	}
}
