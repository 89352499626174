/* Fonts
========================================================================================= */

@font-face {
    font-family: Amberlight;
    src: url(../font/amberlight-webfont.ttf);
}


@font-face {
  font-family: 'icomoon';
  src:  url('../font/icomoon.eot?6lileq');
  src:  url('../font/icomoon.eot?6lileq#iefix') format('embedded-opentype'),
    url('../font/icomoon.ttf?6lileq') format('truetype'),
    url('../font/icomoon.woff?6lileq') format('woff'),
    url('../font/icomoon.svg?6lileq#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-puce:before {
  content: "\e902";
}
.icon-menu:before {
  content: "\e901";
}
.icon-souris:before {
  content: "\e900";
}
.icon-youtube:before {
  content: "\f167";
}
.icon-chevron-down:before {
  content: "\f078";
}
.icon-location:before {
  content: "\e947";
}
.icon-google:before {
  content: "\ea8b";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-linkedin:before {
  content: "\eaca";
}
