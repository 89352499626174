/* Prestation
========================================================================================= */

.title-bg {
	margin-bottom: 3rem;
}

main#page-main {
	
	> section:last-of-type {
		padding-bottom: 12rem;
	}
}

.prestation-item {
	min-height: 30rem;
	margin: 6rem 0;
	position: relative;

	&:last-child {
		margin-bottom: 0;
	}

	&:before{
		content: "";
		position: absolute;
		top: 50%;
		height: 1px;
		width: 100%;
		background-color: $gris;
		z-index: -1;
	}

	&:nth-child(2n) {
		flex-direction: row-reverse;
	}

	.prestation-img-bg {
		max-width: 30rem;
		width: 33%;
		background-color: $gris;

		.prestation-img {
			background-position: 50% 50%;
			background-repeat: no-repeat;
			background-size: cover;
			height: 100%;
			width: 100%;
			opacity: .5;
		}
	}

	.prestation-text {
		width: calc(100% - 39.5%);
		padding: 5rem 6rem;
		background-color: $gris-blanc;

		h5 {
			text-transform: uppercase;
			font-size: 3rem;
			letter-spacing: 4px;
			color: $gris;
			font-weight: 300;
			line-height: 1.5;
		}

		div {
			height: 100%;

			h6 {
				margin-top: 3rem;
				font-size: 1.5rem;
				letter-spacing: 2px;	
				text-transform: uppercase;	
				color: $rose;
			}

			p {
				line-height: 2;
				text-align: justify;

				&:first-of-type {
					margin-top: 0;
				}

				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}
	}
}
