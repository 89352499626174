/* Form */
/* ========================================================================================= */

body {
	form {
		p, .row {
			margin-bottom: 2rem;
			margin-top: 2rem;
		}

		.row {
			margin-left: -1rem;
			margin-right: -1rem;

			.col {
				padding: 0 1rem;
			}
		}

		input[type=text],
		input[type=password],
		input[type=email],
		input[type=url],
		input[type=time],
		input[type=date],
		input[type=datetime],
		input[type=datetime-local],
		input[type=tel],
		input[type=number],
		input[type=search],
		textarea {
			outline: none;
			margin: 0;
			border: solid 1px $gris;
			min-height: 5rem;
			padding: 1rem 2rem;
			box-sizing: border-box;
			resize: none;
			font-size: 1.5rem;
			color: $gris;
			transition: $transition;
			height: inherit;

			&.wpcf7-not-valid {
				border-color: red;
			}

			@include placeholder {
				text-transform: uppercase;
				letter-spacing: 2px;
				line-height: 1.8;
				color: $gris-clair;
			}

			&:focus:not([readonly]) {
				border: solid 1px $rose;
				box-shadow: none;
				outline: none;
				box-shadow: 0 3px 3px 0 rgba(0,0,0,0.14), 0 1px 7px 0 rgba(0,0,0,0.12), 0 3px 1px -1px rgba(0,0,0,0.2);
			}
		}

		textarea{
			padding: 1.5rem 2rem;
		}

		.ajax-loader {
			float: left;
		}

		.wpcf7-not-valid-tip {
			font-size: 1rem;
			margin-top: .5rem;
		}

		.wpcf7-response-output {
			font-size: 1.4rem;
			margin: 0;
			padding: 1rem 2rem;
			border-width: 1px;
			text-align: center;

			&.wpcf7-mail-sent-ok {
				border-color: $rose;
			}
		}
	}
}
