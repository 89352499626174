/* Global
========================================================================================= */

html {
	font-family: Montserrat, sans-serif;
	font-weight: 300;
	font-size: 62.5%;
	scroll-behavior: smooth;
}

.pace-progress {
	background-color: $rose !important;
}

::-moz-selection {
	color: $blanc;
	background: $rose;
}

::selection {
	color: $blanc;
	background: $rose;
}

body {
	background-color: $blanc;
}

p, li {
	font-size: 1.3rem;
	color: $gris;
}

h5 {
	margin-top: 0;
}

a {
	font-size: 1.3rem;
	outline: none;
	color: $rose;
}

video {
	max-width: 100%;
}

strong.list-label  {
	font-weight: 700;
}

.clearfix {
	clear: both;
}

.page {

	main#page-main {
		overflow: hidden;

		ul:not(.collapsible) {
			display: table;

			li {
				display: table-row;
				line-height: 3rem;

				&:before {
					content: "\e902";
					font-family: 'icomoon';
					font-size: 1rem;
					color: $rose;
					font-weight: bold;
					padding-right: 1rem;
					border-radius: 50%;
					display: table-cell;
				}
			}
		}

		#page-top {
			position: relative;
			overflow: hidden;
			height: 50vh;
			margin-bottom: 6rem;

			#page-title {

				h2 {
					padding: 0 1.5rem;
					margin-top: 10rem;
					line-height: .8;
				}
			}
		}
	}
}

.clear {
	clear: both;
}

.btn, .cc-btn{
	background: none;
	box-shadow: none;
	border-radius: 0;
	border-style: solid;
	border-width: 2px;
	min-width: 20rem;
	min-height: 5rem;
	font-size: 1.5rem;
	letter-spacing: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	line-height: 1;
	font-weight: 300;

	&:focus, &.active {
		box-shadow: 0 3px 3px 0 rgba(0,0,0,0.14), 0 1px 7px 0 rgba(0,0,0,0.12), 0 3px 1px -1px rgba(0,0,0,0.2);
	}

	&.btn-rose {
		border-color: $rose;
		color: $rose;

		&:hover, &:focus, &.active {
			background-color: $rose !important;
			color: $blanc;
		}
	}

	&.btn-blanc {
		border-color: $blanc;
		color: $blanc;

		&:hover, &:focus {
			background-color: $blanc !important;
			color: $gris;
		}
	}
}

section {
	padding: 6rem 0;
	overflow: hidden;
}

.section-bg {
	background-color: $gris;

	> img {
		opacity: .2;
	}
}

.font-bg {
	padding: 0 0.6rem;
	background: url('../img/font-bg.jpg') 50% 50% no-repeat;
	background-size: cover;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	color: $rose;
	font-family: Amberlight;
    font-weight: 700;
}

.title-bg {
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background-image: url('../img/title-bg.png');
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: contain;
	min-height: 17rem;
	color: $blanc;
	font-size: 6rem;
	font-family: Amberlight;
	font-weight: bold;
	line-height: 0.8;
	margin-bottom: 6rem;
	padding-top: 4.5rem;
	max-width: 44rem;
	margin-left: auto;
	margin-right: auto;
	margin-top: 0;

	&.double {
		padding-top: 0;
	}

	span {
		text-transform: uppercase;
		font-weight: 300;
		font-size: 2.5rem;
		font-family: Montserrat, sans-serif;
		letter-spacing: 3px;
		line-height: 1;
		margin-bottom: -2.2rem;
	}
}

.flex {
	display: flex;
}

.flex-align-start {
	align-items: flex-start;
}

.flex-align-center {
	align-items: center;
}

.flex-align-end {
	align-items: flex-end;
}

.flex-justify-center {
	justify-content: center;
}

.flex-justify-end {
	justify-content: flex-end;
}

.flex-column {
	flex-direction: column;
}

.flex-wrap {
	flex-wrap: wrap;
}
