/* Média
========================================================================================= */
#media-presse {
	padding-bottom: 12rem;

	.media-titre {

		p {
			margin-bottom: 0;
		}
	}

	.row {
		margin-bottom: 0;
	}
}

#media-video-wrapper {
	margin-top: 6rem;
	text-align: center;
}

#media-photo-wrapper {
	margin-top: 6rem;
	overflow: hidden;

	.photo-item {
		width: 20%;
		height: 20vw;
		float: left;

		.photo-item-bg {
			background-color: $gris;
			height: 100%;
			width: 100%;

			.photo-item-img {
				background-size: cover;
				background-position: 50% 50%;
				background-repeat: no-repeat;
				height: 100%;
				width: 100%;
				opacity: .6;
				transition: $transition;

				&:hover {
					opacity: 1;
				}
			}
		}
	}
}

.presse-item {
	margin-left: 0 !important;
	margin-top: 6rem;
	position: relative;

	&:before {
		content: "";
		position: absolute;
		height: 1px;
		width: 6rem;
		background-color: $gris;
	}

	&:nth-child(2n) {
		padding: 0 6rem 0 3rem;

		&:before {
			right: 0;
			z-index: -1;
			bottom: 12rem;
		}
	}

	&:nth-child(2n+1) {
		padding: 0 3rem 0 6rem;

		&:before {
			left: 0;
			z-index: -1;
			top: 12rem;
		}
	}

	.presse-img-bg {
		height: 24rem;
		width: 100%;
		background-color: $gris;

		.presse-img {
			background-position: 50% 50%;
			background-repeat: no-repeat;
			background-size: cover;
			height: 100%;
			width: 100%;
			opacity: .5;
		}
	}

	.presse-text {
		padding: 2.5rem 3rem;
		height: 100%;
		background-color: $gris-blanc;
		font-size: 1.3rem;
		color: $gris;
		line-height: 1.6;
		text-align: justify;

		h5 {
			font-size: 1.6rem;
			letter-spacing: 2px;	
			text-transform: uppercase;	
			color: $gris;
			margin-bottom: 1.5rem;
			text-align: left;
		}

		h6 {
			font-size: 1.3rem;
			letter-spacing: 2px;	
			text-transform: uppercase;	
			margin-bottom: 1.5rem;
			margin-top: 2rem;
			text-align: left;
		}

		p {

			&:first-of-type {
				margin-top: 0;
			}

			&:last-of-type {
				margin-bottom: 0;
				margin-top: 0;
			}
		}
	}
}