/* Footer
========================================================================================= */

#page-footer {	
	overflow: hidden;

	#page-footer-contact {
		position: relative;
		padding: 6rem 10rem;
		min-height: 30rem;
		overflow: hidden;

		#page-footer-contact-text {
			position: relative;
			font-weight: 200;
			display: flex;
			flex-direction: column;
			align-items: center;

			p {
				color: $blanc;
				font-size: 3rem;
				margin: 0;
				line-height: 2.5;

				strong {
					font-weight: bold;
					font-family: Amberlight;
					font-size: 6rem;
					color: $rose;
					line-height: 0;
					margin-left: 4px;
				}
			}

			a.btn {
				margin-top: 2rem;
				position: relative;
				z-index: 1;
			}
		}
	}

	#contact-map {
		height: 30rem;
		width: 100%;
	}

	#page-footer-top {
		margin-top: 7rem;

		h1 {
			font-family: Amberlight;
			color: $rose;
			font-weight: bold;
			font-size: 6rem;
			margin: 0;
			line-height: 0.5;
			display: inline;
		}

		.row {
			margin-bottom: 0;
			display: flex;

			> div {
				border-left: solid 1px $gris;
				padding: 1rem 2rem 2rem;
			}
			
			#page-footer-coordonnees {
				width: 50%;

				p, a {
					font-size: 1.5rem;
					padding: 0;
					line-height: 1;
					color: $gris;

					span {
						color: $gris-clair;
					}
				}

				> p:first-child {
					margin-top: 0;
					letter-spacing: 2px;
					text-transform: uppercase;
					line-height: 1.8;
					margin-bottom: 3.6rem;
				}

				a {
					text-transform: lowercase;

					&:hover, &:focus{
						color: $rose;
					}
				}
			}

			#page-footer-menu {
				width: 25%;

				ul {
					margin: 0;

					li {

						&.current_page_item a, a:hover, a:focus{
							color: $rose;
						}

						a {
							font-size: 1.5rem;
							letter-spacing: 2px;	
							text-transform: uppercase;	
							color: $gris;
							line-height: 1.8;
						}
					}
				}			
			}

			#page-footer-reseaux-sociaux {
				width: 25%;

				a {
					display: inline-block;
					text-align: center;
					color: $rose;
					margin-right: 1rem;
					margin-bottom: 1rem;

					i {
						font-size: 1.8rem;
						border: solid 2px $rose;
						line-height: 2.6rem;
						height: 3rem;
						width: 3rem;
						transition: $transition;
						display: inline-block;
					}

					&:hover i, &:focus i{
						background-color: $rose;
						color: $blanc;
						box-shadow: 0 3px 3px 0 rgba(0,0,0,0.14), 0 1px 7px 0 rgba(0,0,0,0.12), 0 3px 1px -1px rgba(0,0,0,0.2);
					}

					&:last-child {
						margin-right: 0;
					}
				}
			}
		}

	}

	#page-footer-copyright {
		background-color: $rose;
		min-height: 4rem;

		p{
			margin: 0;
			color: $blanc;

			a {
				color: $blanc;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}

.cc-window.cc-banner {
	padding: 1rem 0rem !important;

	.cc-message {
		text-align: justify;
		margin-right: 2rem;
		margin-left: 2rem;
	}
	
	.cc-compliance {
		margin-right: 2rem;
		margin-left: 2rem;

		.cc-btn {
			border-radius: 0;
			border-style: solid;
			border-width: 2px;
			min-height: 5rem;
			font-size: 1.5rem;
			letter-spacing: 2px;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			line-height: 1;
			font-weight: 300;
			transition: $transition;

			&:hover, &:focus {
				background-color: $blanc;
				color: $gris;
				box-shadow: 0 3px 3px 0 rgba(0,0,0,0.14), 0 1px 7px 0 rgba(0,0,0,0.12), 0 3px 1px -1px rgba(0,0,0,0.2);
			}
		}
	}
}
