/* Variables */
/* ========================================================================================= */

// Media Query Ranges
$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1225px !default;
$large-screen: 1224px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;

$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$large: "only screen and (max-width : #{$large-screen-up})" !default;
$extra-large-and-up: "only screen and (min-width : #{$large-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$large-and-down: "only screen and (max-width : #{$large-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;


@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}  
}

$transition: all 0.2s ease-out;
