/* 404
========================================================================================= */

.error-404 {

	#error-404-content {
		min-height: 100vh;

		h2 {
			font-family: Amberlight;
			font-size: 15rem;
			font-weight: bold;
			line-height: normal;
			padding: 0 3rem;
			margin-bottom: -12rem;
			margin-top: -6rem;
			text-align: center;
		}

		h3 {
			font-size: 2rem;
			text-transform: uppercase;
			letter-spacing: 10px;
			color: $rose;
			margin: 0;
			line-height: 1.2;
			font-size: 2.5rem;
			font-weight: 300;
			text-align: center;
		}

		.btn {
			margin-top: 6rem;
		}
	}
}
