/* Evenmeent
========================================================================================= */

.evenement {

	.evenement-item {
		width: 66.666%;
		margin: 3rem 0;
		position: relative;
		text-align: left;

		&:before {
			content: "";
			position: absolute;
			height: 1px;
			width: 200rem;
			background-color: $gris;
			top: 50%;
		}
		
		&:nth-child(2n+1) {
			padding: 3rem 0rem 3rem 6rem;
			border-left: solid 1px $gris;
			float: left;

			&:before {
				left: 0;
				transform: translateY(-50%) translateX(-100%);
			}
		}

		&:nth-child(2n) {
			padding: 3rem 6rem 3rem 0rem;
			border-right: solid 1px $gris;
			float: right;

			&:before {
				right: 0;
				transform: translateY(-50%) translateX(100%);
			}
		}

		.evenement-date {
			margin: 0;
			font-size: 3rem;
			letter-spacing: 4px;
			text-transform: uppercase;

			span.evenement-date-jour {
				color: $rose;
				font-family: Amberlight;
		 			font-size: 4.5rem;
		 			line-height: 0;
			}
		}

		h4 {
			font-size: 1.5rem;
			letter-spacing: 2px;	
			text-transform: uppercase;	
			color: $rose;
			margin: 2rem 0 1rem;
		}

		p.evenement-description {
			text-align: justify;
			margin: 0;
		}

		ul.evenement-info-list {
			margin: 2rem 0 0;

			li.evenement-info {
				line-height: 2;
			}
		}
	}

	main#page-main {

		section {

			&#evenement-venir {
				padding-bottom: 9rem;
			}

			&#evenement-passe {
				padding-top: 0;
				background-color: $gris-blanc;
				overflow: visible;

				ul.collapsible {
					margin: 0;
					border: none;
					box-shadow: none;
					text-align: center;
					padding: 0 1.5rem;

					.collapsible-header.btn {
						display: inline-flex;
						transform: translateY(-50%);
						background-color: $blanc;

						&:focus {
							background: $blanc !important;
							color: $rose;
							box-shadow: none;
						}
					}

					.collapsible-body {
						border: none;
						padding: 6rem 0 0;
						margin: 0 -1.5rem;

						h3.title-bg {
							opacity: 0.65;
						}

						.evenement-item {
							opacity: 0.65;
						}
					}
				}
			}
		}
	}
}
