/* Page Contact
========================================================================================= */



#contact-content {
	padding-top: 6rem;

	> .container {

		> p {
			font-size: 1.5rem;
			color: $gris;
			letter-spacing: 2px;
			text-transform: uppercase;
			line-height: 1.8;
			margin: 0;
		}

		> .row {
			margin-bottom: 0;
			margin-left: -2rem;
			margin-right: -2rem;

			> .col {
				padding: 0 2rem;
			}
		}
	}


	#contact-form {
		border-right: solid 1px $gris;
		padding-bottom: 12rem;
	}


	#contact-infos {

		#contact-coordonnees {
			padding-top: 4.5rem;

			h1 {
				font-family: Amberlight;
				color: $rose;
				font-weight: bold;
				font-size: 6rem;
				margin: 0;
				line-height: 0.5;
				display: inline;
			}

			p, a {
				font-size: 1.5rem;
				padding: 0;
				line-height: 1;
				color: $gris;

				span {
					color: $gris-clair;
				}
			}

			p:first-of-type {
				margin-top: 0;
				letter-spacing: 2px;
				text-transform: uppercase;
				line-height: 1.8;
				margin-bottom: 3.6rem;
				padding-top: 1rem;
			}

			a {
				text-transform: lowercase;

				&:hover, &:focus{
					color: $rose;
				}
			}
		}

		#contact-reseaux-sociaux {
			margin-top: 3.6rem;

			a {
				height: 3rem;
				width: 3rem;
				display: inline-block;
				text-align: center;
				line-height: 2.85rem;
				border: solid 2px $rose;
				color: $rose;
				font-size: 1.8rem;
				margin-right: 1rem;
				margin-bottom: 1rem;
				transition: $transition;

				&:hover, &:focus {
					background-color: $rose;
					color: $blanc;
					box-shadow: 0 3px 3px 0 rgba(0,0,0,0.14), 0 1px 7px 0 rgba(0,0,0,0.12), 0 3px 1px -1px rgba(0,0,0,0.2);
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
}
