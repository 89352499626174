/* Présentation
========================================================================================= */

#presentation-bio {


	.row {
		margin-bottom: 0;

		#presentation-bio-img {

			img {
				width: 100%;
				position: relative;
				z-index: 1;
			}
		}

		#presentation-bio-text {
			text-align: justify;
			line-height: 2;
			padding-right: 15%;

			h4 {
				margin-bottom: 6rem;
			}

			p {
				position: relative;

				&:first-of-type {

					&:before {
						content: "";
						width: 16rem;
						height: 1px;
						background-color: $gris;
						top: -3rem;
						left: -17%;
						position: absolute;
						z-index: 0;
					}
				}

				&:last-of-type {
					margin-bottom: 0;
				}
				
				strong {
					font-weight: 400;
				}
			}
		}
	}
}

#presentation-formation {
	background-color: $gris-blanc;

	.row {
		margin-bottom: 0;

		#presentation-formation-img {

			img {
				width: 100%;
				position: relative;
				z-index: 1;
			}
		}

		#presentation-formation-text {
			text-align: justify;
			line-height: 2;
			padding-left: 15%;

			h4 {
				margin-bottom: 6rem;
			}

			p {
				position: relative;

				&:first-of-type {

					&:before {
						content: "";
						width: 16rem;
						height: 1px;
						background-color: $gris;
						top: -3rem;
						right: -17%;
						position: absolute;
						z-index: 0;
					}
				}

				&:last-of-type {
					margin-bottom: 0;
				}
				
				strong {
					font-weight: 400;
				}
			}
		}
	}
}

#presentation-experience {
	padding-bottom: 12rem;

	h4 {
		margin-bottom: 6rem;
	}

	#presentation-experience-text {
		text-align: justify;
		line-height: 2;
		.row {
			margin-bottom: 0;

			.col {

				h5 {
					text-transform: uppercase;
					font-size: 3rem;
					letter-spacing: 4px;
					color: $gris;
					font-weight: 300;
					margin-bottom: 3rem;
					text-align: center;
				}

				p {
					position: relative;
					
					strong {
						font-weight: 400;
					}

					&:last-of-type{
						margin-bottom: 0;
					}
				}
			}
		}
	}

	a.btn {
		margin-top: 6rem;
	}
}
