/* Header */
/* ========================================================================================= */

header#page-header {
	width: 100%;

	nav#page-nav {
		position: fixed;
		background: none;
		box-shadow: none;
		z-index: 999;

		.nav-wrapper {

			#nav-menu-top {
				overflow: hidden;
				transition: $transition;
				position: relative;

				&.scroll {
					background-color: $blanc;
					box-shadow: 0 0 20px 0 $gris;

					a#nav-menu-top-logo {
						opacity: 1;
						font-size: 4rem;
						cursor: pointer;
					}

					ul#menu-top {
						padding-left: 24rem;

						&:after {
							display: none;
						}

						li {
							padding: 2rem 1.5rem;

							&:last-child {
								padding-right: 1rem;
							}

							a {
								font-size: 1.3rem;
							}
						}
					}
				}

				a#nav-menu-top-logo {
					color: $rose;
					opacity: 0;
					transition: $transition;
					font-family: Amberlight;
					font-weight: bold;
					font-size: 5rem;
					line-height: 2;
					position: absolute;
					left: 3rem;
					top: 0;
					cursor: default;
				}

				ul {
					position: relative;

					&#menu-top {
						padding-right: 2rem;
						padding-left: 2rem;

						&:after {
							position: absolute;
							content: "";
							width: 6rem;
							height: 1px;
							background-color: $gris;
							right: 0;
							bottom: 0;
						}

						li {
							transition: $transition;
							padding: 3rem 1.6rem;
						}
					}

					li {


						&.current_page_item a{
							color: $rose;
						}

						a {
							transition: $transition;
							color: $gris;
							text-transform: uppercase;
							font-size: 1.5rem;
							letter-spacing: 2px;
							padding: 0;
							line-height: 1;

							&:hover {
								background: none;
								color: $rose;
								transition: none !important;
							}
						}
					}
				}	
			}

			ul#mobile-demo {
				height: calc(100% - 56px);
				box-shadow: none;
				top: 5.6rem; 

				li {
					
					&:first-child{
						padding-top: 2rem;
					}

					a {
						color: $gris;
						text-transform: uppercase;
						font-size: 1.5rem;
						letter-spacing: 2px;
						padding: 0;
						height: auto;
						line-height: 1;
						padding: 2rem 0;
					}
				}
			}
		}
	}
}


.page:not(.error-404) {

	header#page-header {

		nav#page-nav {

			.nav-wrapper {

				#nav-menu-top:not(.scroll) {

					ul#menu-top {

						&:after {
							background-color: $blanc;
						}

						li {

							&.current_page_item a{
								color: $rose;
							}

							a {
								color: $blanc;

								&:hover {
									color: $rose;
								}
							}
						}
					}	
				}
			}
		}
	}
}

