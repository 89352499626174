/* Template
========================================================================================= */

#template-content {
	padding-bottom: 12rem;

	h3 {
		text-transform: uppercase;
		font-size: 3rem;
		letter-spacing: 4px;
		color: $gris;
		font-weight: 300;
		margin-top: 3rem;
	}

	a {
		color: $rose;

		&:hover, &:focus {
			text-decoration: underline;
		}
	}

	p {
		text-align: justify;
		line-height: 1.6;
	}
}
